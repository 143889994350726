<template>
    <div class="item bg-cover bg-home-banner pos-r bs-5" :style="{'background':'url('+ img + ')'}"
         :class="{'full-h':height === 'full','half-h':height === 'half', 'sm-h':height==='sm', 'bg-pos-bt': bgPosition === 'bottom', 'bg-pos-tp':bgPosition === 'top','bg-pos-c':bgPosition === 'center'}">
        <div class="dark-overlay" v-if="overlay"></div>
        <div class="container h-100">
            <div class="content z-index-2 fl-x h-100"
                 :class="{'full-h':height === 'full','half-h':height === 'half', 'fl-a-c':headingAlign==='center','fl-a-e':headingAlign==='bottom'}">
                <section class="banner-section left  z-index-2"><h1
                    class="text-white font-lato-bold c-text-3x bg-dark-trans px-4 py-2" v-if="heading!==''"> {{
                        heading
                    }}</h1>
                    <div class="mt-4 pb-6 pb-md-6 pb-lg-0 text-white">
                        <slot></slot>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script> export default {
    name: 'BaseBanner',
    props: {
        height: { type: String, default: 'half' },
        bgPosition: { type: String, default: 'center' },
        img: { type: Image, default: null },
        overlay: { type: Boolean, default: false },
        heading: { type: String, default: '' },
        headingAlign: { type: String, default: 'bottom' }
    }
};
</script>
<style scoped lang="scss">
.full-h {
    min-height: 65vh;
    @media (min-width: 992px) {
        min-height: 88vh;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 55vh;
    }
}

.half-h {
    min-height: 50vh;
    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 30vh;
    }
}

.sm-h {
    min-height: 40vh;
    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 25vh !important;
    }
}

.z-index-2 {
    z-index: 2 !important;
}

.bg-cover {
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.bg-pos-bt {
    background-position: bottom !important;
}

.bg-pos-tp {
    background-position: top !important;
}

.bg-pos-c {
    background-position: center !important;
}

.bg-dark-trans {
    background-color: rgba(0, 0, 0, 0.4);
}

.text-white {
    color: var(--color-white) !important;
}
//.bg-home-banner { // background: url("../assets/about/banner-1.jpg"); //} .z-index-2 { z-index: 2 !important; } .dark-overlay { top: 0; left: 0; position: absolute; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.6); /*z-index: -1;*/ /*/ / z-index: 2;*/ /*/ / background: var(--color-primary);*/ /*/ / mix-blend-mode: overlay;*/ } .c-text-3x { //font-size: 2rem; font-size: 1.4rem; @media (min-width: 992px) { //font-size: 2.5rem; font-size: 2.2rem; //color: #ed8d22 !important; } }
</style>
