<template>
    <div class="" :class="{'text-center':align==='center','text-left':align==='left', 'text-white': color === 'white'}">
        <h3 class=""
            :class="{'text-uppercase':textUppercase, 'text-primary': color === 'primary', 'bottom-tag-center': bottomTag, 'mb-41':bottomTag,
            'mb-32 fs-lg-4 font-poppins-regular font-weight-600': size === 'sm', 'text-lg font-poppins-bold mb-2': size === 'lg'}">{{ title }}</h3>
        <p class="text-center px-lg-8 lh-2 font-weight-400 text-7" :class="{'text-white-1': color === 'white', 'text-primary': size === 'lg'}">{{ info }}</p>
    </div>
</template>
<script> export default {
    name: 'BaseHeadingWithInfo',
    props: {
        title: {
            type: String
        },
        info: {
            type: String
        },
        align: {
            type: String,
            default: 'center'
        },
        color: {
            type: String,
            default: 'primary'
        },
        bottomTag: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'sm'
        },
        textUppercase: {
            type: Boolean,
            default: true
        }
    }
};
</script>
<style scoped lang="scss">
.bottom-tag {
    &::after {
        content: '';
        position: absolute;
        //width: 5%;
        width: 3rem;
        transform: translateX(-50%);
        height: 2px;
        background-color: rgba(0, 0, 0, 0.65);
        margin-top: 1rem;
    }
}

.bottom-tag-center {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        //width: 5%;
        width: 3.5rem;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        bottom: -1rem;
        //background-color: rgba(0,0,0,0.65);
        //background-color: var(--color-primary);
        background-color: var(--color-white);
        //margin-top: 1rem;
    }
}

</style>
