import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Contact = () => import(/* webpackChunkName: "home" */'../views/Contact-Us');
const Career = () => import(/* webpackChunkName: "home" */'../views/Career');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/career/',
            name: 'Career',
            component: Career

        },
        {
            path: '/contact/',
            name: 'Contact-Us',
            component: Contact

        },
        {
            path: '/Career-InnerPage/',
            name: 'Career-InnerPage',
            component: () => import(/* webpackChunkName: "home" */'../views/Career-InnerPage')

        },
        {
            path: '/Career-Apply/',
            name: 'Career-Apply',
            component: () => import(/* webpackChunkName: "home" */'../views/Career-Form')

        },
        {
            path: '/faq/',
            name: 'Faq',
            component: () => import(/* webpackChunkName: "home" */'../views/Faq')

        },
        {
            path: '/MeetOur-Team/',
            name: 'MeetOur-Team',
            component: () => import(/* webpackChunkName: "home" */'../views/Our-Teams')

        },
        {
            path: '/news/',
            name: 'NewsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/news/NewsPage')

        },
        {
            path: '/news/details/',
            name: 'NewsDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/news/NewsDetailsPage')
        }
    ]
};
