const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');
const Login = () => import('../views/auth/LoginPage');

export default {
    path: '/',
    name: 'AuthLayout',
    redirect: '/login/',
    component: Layout,
    children: [
        {
            path: '/login/',
            name: 'Login',
            component: Login
        },
        {
            path: '/verify-mobile/',
            name: 'VerifyMobile',
            component: () => import('../views/auth/VerifyMobilePage')
        },
        {
            path: '/forgot-password/',
            name: 'ForgotPassword',
            component: () => import('../views/auth/ForgotPassword')
        },
        {
            path: '/password-reset/',
            name: 'PasswordReset',
            component: () => import('../views/auth/PasswordResetLinkComponent')
        },
        {
            path: '/set-new-password/',
            name: 'SetNewPassword',
            component: () => import('../views/auth/SetNewPassword')
        },
        {
            path: '/create-an-account/',
            name: 'CreateAnAccount',
            component: () => import('../views/auth/register/CreateAnAccount')
        },
        {
            path: '/create-an-account-submit/',
            name: 'CreateAnAccount',
            component: () => import('../views/auth/register/CreateAnAccountSubmit')
        },
        {
            path: '/create-an-account/basic-info/',
            name: 'CreateAnAccountBasicInfo',
            component: () => import('../views/auth/register/CreateAccountBasicInfo')
        },
        {
            path: '/create-an-account/choose-registration-type/',
            name: 'CreateAnAccountChooseRegistrationType',
            component: () => import('../views/auth/register/ChooseRegistrationType')
        }
    ]
};
