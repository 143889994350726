<template>
    <div class="auth-card text-white round-2 px-5 pt-5 pb-4"
        :class="{'max-w-28re': size === 'sm', 'max-w-42re': size === 'lg'}">
        <div :class="{'mb-3': backLink || backToLogin}">
            <router-link v-if="backToLogin" to="/login/" class="text-decoration-none">
                <i class="fa fa-chevron-left mr-2"></i> Back to login
            </router-link>
            <div v-if="backLink" @click="$router.go(-1)" to="/login/" class="text-decoration-none cursor-pointer">
                <i class="fa fa-chevron-left mr-2"></i> Back
            </div>
        </div>
        <div class="mb-3">
            <slot name="buttons"></slot>
        </div>
        <h2 class="fs-lg-7 font-poppins-medium text-center" v-if="heading !== ''">{{ heading }}</h2>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AuthCard',
    props: {
        heading: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        backLink: {
            type: Boolean,
            default: false
        },
        backToLogin: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="scss">
.auth-card {
    background-color: rgba(34, 45, 101, 0.7);
    @media (min-width: 992px) {
        min-width: 27rem !important;
    }
}

.max-w-28re {
    max-width: 28rem !important;
}
.max-w-42re {
    max-width: 55rem !important;
}
</style>
