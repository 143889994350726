<template>
    <div class="py-7 place-holder-white auth-inputs input-basic-b">
        <div class="container text-white">
            <div class="fl-x fl-j-c">
                <auth-card :heading="heading" :size="size" :backLink="backLink" :back-to-login="backToLogin">
                    <template #buttons>
                        <div>
                            <slot name="buttons"></slot>
                        </div>
                    </template>
                    <template #default>
                        <div class="mb-41 mt-4 fs--2" v-if="$slots.subSection">
                            <slot name="subSection"></slot>
                        </div>
                        <slot></slot>
                    </template>
                </auth-card>
            </div>
        </div>
    </div>
</template>

<script>
import AuthCard from '@components/auth/AuthCard';

export default {
    name: 'AuthPageTemplate',
    components: { AuthCard },
    props: {
        heading: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        backLink: {
            type: Boolean,
            default: false
        },
        backToLogin: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>

</style>
